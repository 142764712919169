body {
  padding-bottom: 40px;
  color: #5a5a5a;
  font-size: 14px;
  line-height: 1.428571429;
  font-family: Arial, "文泉驛正黑", "WenQuanYi Zen Hei", "儷黑 Pro", "LiHei Pro", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  margin: 0;
  padding: 0;
}

nav {
  height: 50px;
  background-color: #cccccc;
  border-bottom-right-radius: 10px;
  box-shadow: 1px 1px 3px;
  z-index: 1031;

  .navbar-collapse {
    border-right: 1px solid #aaa;

    .nav-item {
      width: 14%;
      text-align: center;
      border-left: 1px solid #aaa;
      .nav-link {
        padding: .688rem 0;
        color: #3e3a39;

        &.nav-img {
          display: flex;
          padding: 0;
          height: 100%;
          align-items: center;
          justify-content: center;

          img {
            width: 35px;
          }
        }
      }

      &:hover {
        background-color: #e8e8e8;
      }

      &.active {
        background-color: #C6893F;
        .nav-link {
          color: white;
        }  
      }
    }
  }

  .navbar-brand {
    margin-left: .5rem;
    margin-right: 0;
  }
}

main {
  padding-top: 50px;
  padding-bottom: 50px;
}

footer {
  height: 37px;
}

a {
  color: #428bca;
}

input[type="radio"] {
  transform:scale(1.5);
  cursor: pointer;
}

.text-blue {
  color: #0052A4;
}

.header {
  color: #aaa;
  font-size: 28px;
  position: relative;
  display: inline-block;
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 0;
    border: 2px solid #6b94cf;
    border-radius : 5px;
  }
}

.avatar {
  width: 150px;
  height: 200px;
  border: 5px solid #E9EFF5;
  border-radius: 5px;
}

.ellipsis {
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.button {
  display: inline-block;
  color: #939393;
  background: linear-gradient(#fcfdfd, #eaeae9);
  border: 0px solid transparent;
  border-radius: .4rem;
  padding: 0.25rem 1rem 0 1.25rem;
  font-size: 17px;
  line-height: 2;
  letter-spacing: .25rem;
  font-weight: 400;
  box-shadow: 0 0 3px;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;

  &:hover {
    color: #939393;
    opacity: .9;
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }

  .icon {
    padding: 4px 0;
  }
}

a.button.disabled, fieldset:disabled a.button {
  pointer-events: none;
}

.button-blue {
  color: #403e6c;
  background: linear-gradient(#d0e8f2, #6797d3);
  &:hover {
    color: #403e6c;
  }
}

.button-red {
  color: #980a00;
  background: linear-gradient(#ffaea8, #f3594d);
  &:hover {
    color: #980a00;
  }
}


.img-button {
  height: 38px;
  display: inline-block;
  border: 0px solid transparent;
  border-radius: .4rem;
  box-shadow: 0 0 3px;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;

  &:hover {
    opacity: .9;
  }

  &:focus {
    outline: 0;
  }
}

.checkbox {
  display: block;
  position: relative;
  height: 32px;
  width: 30px;
  margin-top: auto;
  cursor: pointer;
  clear: both;

  input {
    position: absolute;
    height: 30px;
    width: 30px;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
  }

  input:checked ~ span:after {
    border: solid #3374af;
    border-width: 0 4px 4px 0;
  }
  
  span {
    position: absolute;
    top: 2px;
    height: 30px;
    width: 30px;
    background: linear-gradient(135deg, #edf0f2, #e5e7e8);
    border-radius: 5px;
    box-shadow: 1px 1px 4px #9a9a9a;

    &:after {
      position: absolute;
      content: "";
      left: 8px;
      width: 15px;
      height: 25px;
      background-color: transparent;
      transform: rotate(45deg) scale(1);
    }
  }
}

.icon {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(135deg, #5988c3, #8fafd8);
  background-clip: text;
}

.icon-yellow {
  background: linear-gradient(135deg, #f9be00, #f7f000);
  background-clip: text;
}

.icon-grey {
  background: linear-gradient(135deg, #edf0f2, #e5e7e8);
  background-clip: text;
}

.icon-shadow {
  filter: drop-shadow(1px 1px 2px #9a9a9a);
}

.border-secondary {
  border-color: #ccc!important;
}

.border-dark {
  border-color: #acacac!important;
}

.table {
  width: 100%;
  font-size: 18px;
  margin-bottom: 15px;
  color: inherit;
  text-align: center;

  thead {
    th {
      color: #0052A4;
    }
  }

  tr {
    border-bottom: 1px solid #e0e0e0;
  }

  th {
    background: linear-gradient(to bottom, #f5f6f6 0%,#dbdce2 49%,#f5f6f6 100%);
    padding: 5px;
  }

  td {
    padding: 5px;
  }
}

.employee-card {
  .card-photo {
    width: 120px;
    height: 150px;
    border: 5px solid #E9EFF5;
    border-radius: 5px;
  }

  .card-menu {
    position: absolute;
    left: 10px;
    bottom: 8px;
    .card-star {
      font-size: 35px;
      cursor: pointer;
    }
  }
  
  .card-row {
    color: #727171;
    border-bottom: solid 1px #5ba9de;
  }
}

@media (min-width: 992px) {
  .w-lg-75 {
    width: 75%!important;
  }
}

@media (min-width: 1200px) {
  .w-xl-75 {
    width: 75%!important;
  }
}
